import React, { useState, useEffect, Suspense } from 'react'
import './styles.css'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import firebase from './firebase'
import './App.css';
import './detalles.css';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "bootstrap/dist/js/bootstrap.min.js";


import Nav from './Pagina_Web/Componentes/Nav/nav';
//import Inicio from './paginas/inicio';
import Register from '../App/Auth/Register'
//const Register = React.lazy(() => import("../../components/Auth/Register"));
const SendPas = React.lazy(() => import("../App/Auth/Send-pass"));
const Inicio = React.lazy(() => import("./Pagina_Web/Componentes/inicio/inicio"));
const Dashboard = React.lazy(() => import('./Portal_Usuarios/index'));
const Servicios = React.lazy(() => import("./Pagina_Web/Componentes/Planes/Planes.component"));
const Login = React.lazy(() => import("../App/Auth/Login"));


export const theme = createMuiTheme({
	html: {
		width: '100vw'
	},
	palette: {
		primary: {
			main: '#000000',
		},
		secondary: {
			main: '#18ffff',
		},
	},

});


export default function App() {
	require('dotenv').config();

	const [firebaseInitialized, setFirebaseInitialized] = useState(false)

	useEffect(() => {
		firebase.isInitialized().then(val => {
			setFirebaseInitialized(val)
		})
	})


	return firebaseInitialized !== false ? (
		<MuiThemeProvider theme={theme}>
			<Router>
				<Switch>
					<Route exact path="/dashboard" >
						<Suspense fallback={<div id="loader"><CircularProgress /></div>}>
							<Dashboard />
						</Suspense>
					</Route>
					<Route exact path="/dashboard/:id" component={Dashboard} />
					<Route exact path="/home" component={Dashboard} />
					<div className="container-fluid px-0">
						<Nav />												
						<Route exact path={['/', '/inicio']}>
							<Suspense fallback={<div id="loader"><CircularProgress /></div>}>
								<Inicio/>
							</Suspense>
						</Route>
						<Route path="/planes" >
							<Suspense fallback={<div id="loader"><CircularProgress /></div>}>
								<Servicios/>
							</Suspense>
						</Route>
						<Route path="/login" >
							<Suspense fallback={<div id="loader"><CircularProgress /></div>}>
								<Login/>
							</Suspense>
						</Route>
						<Route exact path="/register" >
							
								<Register/>
						
						</Route>
						<Route exact path="/resetpas" >
							<Suspense fallback={<div id="loader"><CircularProgress /></div>}>
								<SendPas/>
							</Suspense>
						</Route>
					</div>

				</Switch>
			</Router>

		</MuiThemeProvider>

	) : <div id="loader"><CircularProgress /></div>
}