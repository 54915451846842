import React, { useState } from 'react'
import { Typography, Paper, Avatar, Button, FormControl, Input, InputLabel, CircularProgress } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link, withRouter } from 'react-router-dom'
import firebase from '../../firebase'
import 'bootswatch/dist/litera/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
const styles = theme => ({
	main: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
})

function Register(props) {
	const { classes } = props

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [habilitar, setHabilitar] = useState(false)
	const [open, setOpen] = React.useState(false);
	const [leyenda, setLeyenda] = React.useState('');
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setHabilitar(false);
	};




	return (
		<main className={classes.main}>
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Registrar Cuenta
       			</Typography>
				<form className={classes.form} onSubmit={e => e.preventDefault() && false} noValidate>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="name">Nombre</InputLabel>
						<Input id="name" name="name" autoComplete="off" autoFocus value={name} onChange={e => setName(e.target.value)} disabled={habilitar} required />
					</FormControl>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="email">Correo Electrónico</InputLabel>
						<Input id="email" type="email" name="email" autoComplete="off" value={email} onChange={e => setEmail(e.target.value)} disabled={habilitar} required />
					</FormControl>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="password">Contraseña</InputLabel>
						<Input name="password" type="password" id="password" autoComplete="off" value={password} onChange={e => setPassword(e.target.value)} disabled={habilitar} required />
					</FormControl>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						onClick={onRegister}
						className={classes.submit}
						disabled={habilitar}
					>
						{habilitar ? <CircularProgress size={20} /> : 'Registrar'}
						{habilitar ? 'Registrando' : ''}

					</Button>

					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="secondary"
						component={Link}
						to="/login"
						className={classes.submit}
						disabled={habilitar}>
						Regresar a Login
          			</Button>
				</form>
			</Paper>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Tuvimos un problema"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{leyenda}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" autoFocus>
						OK
				</Button>
				</DialogActions>
			</Dialog>
		</main>

	)

	async function onRegister() {
		//eslint-disable-next-line
		let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if(!regEmail.test(email)){
			setLeyenda('Correo Electrónico inválido')
			await handleClickOpen();
			return;
		}
		if (name === '' || email === '' || password === '') {
			setLeyenda('Complete todos los campos')
			await handleClickOpen();
			return;
		}

		try {
			setHabilitar(true);
			await firebase.register(name, email, password)
			props.history.push('/dashboard')
		} catch (error) {
			switch (error.code) {
				case "auth/invalid-email":
					setLeyenda('Formato de correo invalido')
					break;
				case "auth/email-already-in-use":
					setLeyenda('El correo ya esta en uso en otra cuenta')
					break;
				case "auth/weak-password":
					setLeyenda('La contraseña debe tener mínimo 6 caracteres')
					break;
				default:
					setLeyenda('Sucedio algun problema intenta de nuevo')
			}
			await handleClickOpen();
		}


	}
}

export default withRouter(withStyles(styles)(Register))