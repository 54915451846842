import React from 'react'
import { withRouter } from 'react-router-dom'
import logo from '../../../Imagenes/logo.jpg';
import { NavLink } from 'react-router-dom';
import { Button } from '@material-ui/core'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { options, optionsRight } from './Models/menu.lista';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({

}))(MenuItem);



function Nav() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div >
      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <NavLink to='/' className="navbar-brand" activeClassName="active">
            <img alt="My Forex Traders" src={logo} style={{ width: '48px' }} />
            My Forex Traders
          </NavLink>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              {options.map((option, index) => (
                (<li className="nav-item" key={index}>
                    <NavLink
                      to={option['url']}
                      className={option['class']}
                      activeClassName="nav-link active"
                      exact
                    >
                      {option['icon']}
                      {option['lema']}
                    </NavLink>
                  </li>)
              ))}
            </ul>
            <ul className="nav navbar-nav navbar-right">
              {optionsRight.map((option, indexRight) => (              
                  <li className="nav-item" key={indexRight}>
                    <NavLink
                      to={option['url']}
                      onClick={(event) => handleMenuItemClick(event, indexRight)}
                      className={option['class']}
                      activeClassName="nav-link active"
                      exact
                    >
                      {option['icon']}
                      {option['lema']}
                    </NavLink>
                  </li>
              ))}
            </ul>
          </div>
          {/*Menu para moviles */}
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="secondary"
            onClick={handleClick}
            className="navbar-toggler"
          >
            <span className="navbar-toggler-icon"></span>
          </Button>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {options.map((option, index) => (
              <StyledMenuItem
                onClick={(event) => handleMenuItemClick(event, index)}
                component={NavLink}
                key={option['lema']}
                to={option['url']}
                activeClassName="Mui-selected"
                exact
              >
                <ListItemIcon>
                  {option['icon']}
                </ListItemIcon>
                <ListItemText primary={option['lema']} />
              </StyledMenuItem>
            ))}
            {optionsRight.map((option, indexRight) => (
              <StyledMenuItem
                onClick={(event) => handleMenuItemClick(event, indexRight)}
                component={NavLink}
                key={option['lema']}
                to={option['url']}
                activeClassName="Mui-selected"
                exact
              >
                <ListItemIcon>
                  {option['icon']}
                </ListItemIcon>
                <ListItemText primary={option['lema']} />
              </StyledMenuItem>
            ))}
          </StyledMenu>
        </div>

      </nav>
    </div >
  );

}

export default withRouter((Nav))