import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-firestore'

const config = {
	apiKey: "AIzaSyC-mxpqoV3axVuv5SLMb-cOAodIPsfV-Vs",
	authDomain: "mftecuador.firebaseapp.com",
	projectId: "mftecuador",
	storageBucket: "mftecuador.appspot.com",
	messagingSenderId: "673183937790",
	appId: "1:673183937790:web:516983503ed1bafb8121af",
	measurementId: "G-KFP4DKLMQM"
}

class Firebase {
	constructor() {
		app.initializeApp(config)
		this.auth = app.auth()
		this.db = app.firestore()
	}

	login(email, password) {
		return this.auth.signInWithEmailAndPassword(email, password)
	}

	logout() {
		return this.auth.signOut()
	}

	async register(name, email, password) {
		await this.auth.createUserWithEmailAndPassword(email, password)
		return this.auth.currentUser.updateProfile({
			displayName: name
		})
	}

	addQuote(quote) {
		if(!this.auth.currentUser) {
			return alert('Not authorized')
		}

		return this.db.doc(`users_codedamn_video/${this.auth.currentUser.uid}`).set({
			quote
		})
	}

	isInitialized() {
		return new Promise(resolve => {
			this.auth.onAuthStateChanged(resolve)
		})
	}

	getCurrentUsername() {
		return this.auth.currentUser && this.auth.currentUser.displayName
	}
	async getAccesToken() {
		const token = await this.auth.currentUser.getIdToken()
		.then(res=>{
			return res
		}).catch(error=>{
			return error
		})
		return token
	}
	getVerifyEmail() {
		return this.auth.currentUser.emailVerified
	}
	SendEmailVerify(){
		var user = this.auth.currentUser;
		
		return user.sendEmailVerification();
	}
	async ResetPassword(email){
		var auth = this.auth;
		return auth.sendPasswordResetEmail(email)
	}

	async getCurrentUserQuote() {
		const quote = await this.db.doc(`users_codedamn_video/${this.auth.currentUser.uid}`).get()
		return quote.get('quote')
	}
}

export default new Firebase()