import { Home,BarChart, LockOpen, Assignment } from '@material-ui/icons'

export const options = [
    { lema: 'Inicio', url: '/inicio', icon: <Home fontSize="small" />, class: 'nav-link' },
    { lema: 'Planes', url: '/planes', icon: <BarChart fontSize="small" />, class: 'nav-link' },
];

export const optionsRight = [
    { lema: 'Iniciar Sesión', url: '/login', icon: <LockOpen fontSize="small" />, class: 'nav-link', class_right: 'nav navbar-nav navbar-right' },
    { lema: 'Registrarse', url: '/register', icon: <Assignment fontSize="small" />, class: 'nav-link ' },
];